import React from "react"  
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Release from "../components/Release"
import Footer from '../components/footer'
import Container from 'react-bootstrap/Container'
import BreadCrumb from './../components/breadcrumb'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import './../components/release.css'

export const query = graphql`  
  query RepoQuery($id: Int!) {
    repo: strapiRepos(strapiId: {eq: $id}) {
      group_name
      repo_name
      releases {
        assets {
          name
          id
          url
        }
        create_time
        description
        id
        tag_name
      }
    }
  }
`

const Releases = ({ data }) => { 
  let tab = 'v4', tabArr = ['v2', 'v3', 'v4'];
  if(typeof window !== "undefined") {
    const v = window.location.href.substring(window.location.href.lastIndexOf('/')-2, window.location.href.lastIndexOf('/'))
    tab =  tabArr.indexOf(v)>-1 ? v : 'v4'
  }
  const [ active, setActive ] = React.useState(tab)
  let arr = [ { title :'4.0', eventKey: 'v4'  }, { title :'3.0', eventKey: 'v3'  } ]
  const repo = data.repo

  return (
    <>
      <div className="home-bg" style={{ background: `url('')`, height: '7.5rem' }}>
        <Layout>
          {/* <SEO title={`版本-${repo.repo_name}`} /> */}
          <SEO title={`物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台`} />
        </Layout>
      </div>
      <Container>
        <BreadCrumb title="模块列表" href="/repos/front" style={{ margin: '2rem 0' }} flag={true}></BreadCrumb>
        <h5><span className="text-muted" style={{ fontSize: '0.8em' }}>[{repo.group_name}]</span> {repo.repo_name}</h5>
        <h4>版本列表</h4>
        {
          repo.releases.filter(item=> item).sort((a, b) => b.id - a.id).filter(item => {
            if (active == 'v2') {
              if(item?.tag_name.includes(active) || item?.tag_name.includes('v0') || item?.tag_name.includes('v1')) {
                return item
              }
            } else {
              return item?.tag_name.includes(active)
            }
          })
            .map(r => <Release release={r} showRepo={false} />)
        }
      </Container>
      <Footer style={{ marginTop: '7.5rem' }}></Footer>
    </>
  )
}

export default Releases  